/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_VERIFY_PASSWORD = 'FORGOT_VERIFY_PASSWORD';
export const FORGOT_VERIFY_PASSWORD_SUCCESS = 'FORGOT_VERIFY_PASSWORD_SUCCESS';
export const FORGOT_VERIFY_PASSWORD_ERROR = 'FORGOT_VERIFY_PASSWORD_ERROR';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* METRICAS DATES */
export const DATES = 'DATES';

/* CHAT APP */
export const CHAT_GET_CONTACTS = 'CHAT_GET_CONTACTS';
export const CHAT_GET_CONTACTS_SUCCESS = 'CHAT_GET_CONTACTS_SUCCESS';
export const CHAT_GET_CONTACTS_ERROR = 'CHAT_GET_CONTACTS_ERROR';
export const CHAT_GET_CONVERSATIONS = 'CHAT_GET_CONVERSATIONS';
export const CHAT_GET_CONVERSATIONS_SUCCESS = 'CHAT_GET_CONVERSATIONS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_ERROR = 'CHAT_GET_CONVERSATIONS_ERROR';
export const CHAT_LOADER_MORE_CONVERSATIONS = 'CHAT_LOADER_MORE_CONVERSATIONS';
export const CHAT_LOADER_MORE_CONVERSATIONS_SUCCESS =
  'CHAT_LOADER_MORE_CONVERSATIONS_SUCCESS';
export const CHAT_LOADER_MORE_CONVERSATIONS_ERROR =
  'CHAT_LOADER_MORE_CONVERSATIONS_ERROR';
export const CHAT_GET_CONVERSATIONS_CHATROOMS =
  'CHAT_GET_CONVERSATIONS_CHATROOMS';
export const CHAT_GET_CONVERSATIONS_CHATROOMS_SUCCESS =
  'CHAT_GET_CONVERSATIONS_CHATROOMS_SUCCESS';
export const CHAT_GET_CONVERSATIONS_CHATROOMS_ERROR =
  'CHAT_GET_CONVERSATIONS_CHATROOMS_ERROR';
export const CHAT_ADD_MESSAGE_TO_CONVERSATION =
  'CHAT_ADD_MESSAGE_TO_CONVERSATION';
export const NEW_MESSAGES_SUCCESS = 'NEW_MESSAGES_SUCCESS';
export const CHAT_CREATE_CONVERSATION = 'CHAT_CREATE_CONVERSATION';
export const CHAT_SEARCH_CONTACT = 'CHAT_SEARCH_CONTACT';
export const CHAT_CHANGE_CONVERSATION = 'CHAT_CHANGE_CONVERSATION';
export const START_CHAT_WEBSOCKET = 'START_CHAT_WEBSOCKET';
export const CHAT_WEBSOCKET_MESSAGE_RECEIVED =
  'CHAT_WEBSOCKET_MESSAGE_RECEIVED';
export const STOP_CHAT_WEBSOCKET = 'STOP_CHAT_WEBSOCKET';

/* account */
export const ACCOUNT_ACTIVE = 'ACCOUNT_ACTIVE';
export const ACCOUNTS = 'ACCOUNTS';

/* user metricool */
export const GET_PERFIL = 'GET_PERFIL';
export const GET_PERFIL_SUCCESS = 'GET_PERFIL_SUCCESS';
export const GET_PERFIL_ERROR = 'GET_PERFIL_ERROR';

/* shop */
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';

export const CHECKOUT_REQUEST = 'CHECKOUT_REQUEST';
export const CHECKOUT_SUCCESS = 'CHECKOUT_SUCCESS';
export const CHECKOUT_FAILURE = 'CHECKOUT_FAILURE';

export const GET_ALL_SUBCRIPTION_USER = 'PERFIL_USER';
export const GET_ALL_SUBCRIPTION_USER_SUCCESS = 'PERFIL_USER_SUCCESS';
export const GET_ALL_SUBCRIPTION_USER_FAILURE = 'PERFIL_USER_FAILURE';

/* User perfil */
export const GET_PERFIL_USER = 'PERFIL_USER';
export const GET_PERFIL_USER_SUCCESS = 'PERFIL_USER_SUCCESS';
export const GET_PERFIL_USER_FAILURE = 'PERFIL_USER_FAILURE';

export const UN_SUBSCRIBED_PLAN_USER = 'UN_SUBSCRIBED_PLAN_USER';
export const UN_SUBSCRIBED_PLAN_USER_SUCCESS = 'UN_SUBSCRIBED_PLAN_USER';
export const UN_SUBSCRIBED_PLAN_USER_FAILURE = 'UN_SUBSCRIBED_PLAN_USER';

export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';

export const GET_CONTANT_PAYMENT = 'GET_CONTANT_PAYMENT';
export const GET_CONTANT_PAYMENT_SUCCESS = 'GET_CONTANT_PAYMENT_SUCCESS';
export const GET_CONTANT_PAYMENT_FAILURE = 'GET_CONTANT_PAYMENT_FAILURE';

export const SUBSCRIBED_SERVICE = 'SUBSCRIBED_SERVICE';
export const SUBSCRIBED_SERVICE_SUCCESS = 'SUBSCRIBED_SERVICE';
export const SUBSCRIBED_SERVICE_FAILURE = 'SUBSCRIBEDSERVICE';

export const GET_SERVICE_ADDONS = 'GET_SERVICE_ADDONS';
export const GET_SERVICE_ADDONS_SUCCESS = 'GET_SERVICE_ADDONS_SUCCESS';
export const GET_SERVICE_ADDONS_FAILURE = 'GET_CONTANT_PAYMENT_FAILURE';
