import {
  GET_PERFIL_USER,
  GET_PERFIL_USER_SUCCESS,
  GET_PERFIL_USER_FAILURE,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILURE,
  GET_CONTANT_PAYMENT,
  GET_CONTANT_PAYMENT_FAILURE,
  GET_CONTANT_PAYMENT_SUCCESS,
} from 'redux/contants';

// Estado inicial del perfil de usuario
const initialState = {
  perfilData: [],
  perfilDataLoading: false,
  perfilDataError: null,
  invoiceData: [],
  invoiceDataLoading: false,
  invoiceDataError: null,
  contactPayment: [],
  contactPaymentLoading: false,
  contactPaymentError: [],
  unSubscribedLoading: false,
};

// Reducer para el perfil de usuario y la factura
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PERFIL_USER:
      return {
        ...state,
        perfilDataLoading: true,
        perfilDataError: null,
      };
    case GET_PERFIL_USER_SUCCESS:
      return {
        ...state,
        perfilData: action.payload,
        perfilDataLoading: false,
        perfilDataError: null,
      };
    case GET_PERFIL_USER_FAILURE:
      return {
        ...state,
        perfilDataLoading: false,
        perfilDataError: action.payload,
      };
    case GET_INVOICE:
      return {
        ...state,
        invoiceDataLoading: true,
        invoiceDataError: null,
      };
    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        invoiceData: action.payload,
        invoiceDataLoading: false,
        invoiceDataError: null,
      };
    case GET_INVOICE_FAILURE:
      return {
        ...state,
        invoiceDataLoading: false,
        invoiceDataError: action.payload,
      };
    case GET_CONTANT_PAYMENT:
      return {
        ...state,
        contactPaymentLoading: true,
        contactPaymentError: null,
      };
    case GET_CONTANT_PAYMENT_SUCCESS:
      return {
        ...state,
        contactPayment: action.payload,
        contactPaymentLoading: false,
        contactPaymentError: null,
      };
    case GET_CONTANT_PAYMENT_FAILURE:
      return {
        ...state,
        contactPaymentLoading: false,
        contactPaymentError: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
