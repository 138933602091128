/* eslint-disable import/prefer-default-export */
export const ThemeColors = () => {
  const rootStyle = getComputedStyle(document.body);
  return {
    themeColor1: rootStyle.getPropertyValue('--theme-color-1').trim(),
    themeColor2: rootStyle.getPropertyValue('--theme-color-2').trim(),
    themeColor3: rootStyle.getPropertyValue('--theme-color-3').trim(),
    themeColor4: rootStyle.getPropertyValue('--theme-color-4').trim(),
    themeColor5: rootStyle.getPropertyValue('--theme-color-5').trim(),
    themeColor6: rootStyle.getPropertyValue('--theme-color-6').trim(),
    graficaColor1: rootStyle.getPropertyValue('--color-grafica-1').trim(),
    graficaColor2: rootStyle.getPropertyValue('--color-grafica-2').trim(),
    graficaColor3: rootStyle.getPropertyValue('--color-grafica-3').trim(),
    graficaColor4: rootStyle.getPropertyValue('--color-grafica-4').trim(),
    graficaColor5: rootStyle.getPropertyValue('--color-grafica-5').trim(),
    graficaColor6: rootStyle.getPropertyValue('--color-grafica-6').trim(),
    graficaColorPost: rootStyle.getPropertyValue('--color-grafica-post').trim(),
    graficaColorReel: rootStyle
      .getPropertyValue('--color-grafica-reels')
      .trim(),
    graficaColorStories: rootStyle
      .getPropertyValue('--color-grafica-stories')
      .trim(),
    themeColor1_10: rootStyle.getPropertyValue('--theme-color-1-10').trim(),
    themeColor2_10: rootStyle.getPropertyValue('--theme-color-2-10').trim(),
    themeColor3_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    themeColor4_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    themeColor5_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    themeColor6_10: rootStyle.getPropertyValue('--theme-color-3-10').trim(),
    graficaColor1_10: rootStyle.getPropertyValue('--color-grafica-1-10').trim(),
    graficaColor2_10: rootStyle.getPropertyValue('--color-grafica-2-10').trim(),
    graficaColor3_10: rootStyle.getPropertyValue('--color-grafica-3-10').trim(),
    graficaColor4_10: rootStyle.getPropertyValue('--color-grafica-3-10').trim(),
    graficaColor5_10: rootStyle.getPropertyValue('--color-grafica-3-10').trim(),
    graficaColor6_10: rootStyle.getPropertyValue('--color-grafica-3-10').trim(),
    graficaColorPost_10: rootStyle
      .getPropertyValue('--color-grafica-post-10')
      .trim(),
    graficaColorReel_10: rootStyle
      .getPropertyValue('--color-grafica-reels-10')
      .trim(),
    graficaColorStories_10: rootStyle
      .getPropertyValue('--color-grafica-stories-10')
      .trim(),
    primaryColor: rootStyle.getPropertyValue('--primary-color').trim(),
    foregroundColor: rootStyle.getPropertyValue('--foreground-color').trim(),
    separatorColor: rootStyle.getPropertyValue('--separator-color').trim(),
  };
};
