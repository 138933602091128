import moment from 'moment';
import { DATES } from '../contants';

const today = moment().subtract(1, 'days').toDate();
const todayISO = today.toISOString();
const firstDayOfMonth = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate() - 7
).toISOString();

const initialState = {
  currentDates: [firstDayOfMonth, todayISO],
  currentdateLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DATES:
      return {
        ...state,
        currentDates: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
