/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  date: ""
};

export const createPlanification = createAsyncThunk(
  'planification/createPlanification',
  // eslint-disable-next-line camelcase
  async ({month_year, blog_id},  {rejectWithValue}) => {
      try {
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/v1/planifications`,
          // eslint-disable-next-line camelcase
          {planification: {month_year, blog_id}} ,  // esto se convierte en data
          {
          headers: {
              'content-Type': 'application/json',
              token: localStorage.getItem('jwt'),
          },
          }
      );
      const planification = await response.data;
      
      return planification;
      } catch (error) {
      if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
      }
  }
  );

export const calendarPlanificacion = createSlice({
  name: 'planificacion',
  initialState,
  reducers: {
    changeDates: (state, action) => {
      state.date = action.payload;
    },
  },
  extraReducers: {},
});

export const { changeDates } = calendarPlanificacion.actions;

export default calendarPlanificacion.reducer;
