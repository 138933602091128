import {
  GET_PERFIL_USER,
  GET_PERFIL_USER_FAILURE,
  GET_PERFIL_USER_SUCCESS,
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILURE,
  GET_CONTANT_PAYMENT,
  GET_CONTANT_PAYMENT_FAILURE,
  GET_CONTANT_PAYMENT_SUCCESS,
  UN_SUBSCRIBED_PLAN_USER,
  UN_SUBSCRIBED_PLAN_USER_SUCCESS,
  UN_SUBSCRIBED_PLAN_USER_FAILURE,
} from 'redux/contants';

export const perfilUser = () => ({
  type: GET_PERFIL_USER,
});

export const perfilUserSuccess = (data) => ({
  type: GET_PERFIL_USER_SUCCESS,
  payload: data,
});

export const perfilUserFailure = (error) => ({
  type: GET_PERFIL_USER_FAILURE,
  payload: error,
});

export const getInvoice = () => ({
  type: GET_INVOICE,
});

export const getInvoiceSuccess = (data) => ({
  type: GET_INVOICE_SUCCESS,
  payload: data,
});

export const getInvoiceFailure = (error) => ({
  type: GET_INVOICE_FAILURE,
  payload: error,
});

export const getContactPayment = () => ({
  type: GET_CONTANT_PAYMENT,
});

export const getContactPaymentSuccess = (data) => ({
  type: GET_CONTANT_PAYMENT_SUCCESS,
  payload: data,
});

export const getContactPaymentFailure = (error) => ({
  type: GET_CONTANT_PAYMENT_FAILURE,
  payload: error,
});

export const unSubcribed = (id) => ({
  type: UN_SUBSCRIBED_PLAN_USER,
  payload: id,
});

export const unSubcribedSuccess = (data) => ({
  type: UN_SUBSCRIBED_PLAN_USER_SUCCESS,
  payload: data,
});

export const unSubcribedFailure = (error) => ({
  type: UN_SUBSCRIBED_PLAN_USER_FAILURE,
  payload: error,
});
