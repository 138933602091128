import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import dates from './dates/reducer';
import account from './account/reducer';
import chatApp from './chat/reducer';
import notificationDevices from './slices/notifications/notification';

import userPerfil from './userMetricool/reducer';
import services from './services/reducer';
import gestorPerfil from './perfilUser/reducer';
import perfilUser from './slices/perfilUser/perfilUser';
import servicesProducts from './slices/services/services';
import calendarPlan from './slices/planificación/calendar';
import admin from './slices/admin/admin';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  dates,
  chatApp,
  account,
  userPerfil,
  notificationDevices,
  perfilUser,
  services,
  servicesProducts,
  gestorPerfil,
  calendarPlan,
  admin
});

export default reducers;
