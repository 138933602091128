import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { adminRoot } from 'constants/defaultValues';
import { setCurrentUser, getCurrentUser } from 'helpers/Utils';
import { setAccounts, setAccountsActive } from 'redux/actions';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_VERIFY_PASSWORD,
  RESET_PASSWORD,
} from '../contants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotVerifyPasswordSuccess,
  forgotVerifyPasswordError,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (email, password) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: { email, password } }),
  });

  const result = await response.json();

  if (!response.ok) {
    throw result; // Esto arrojará el objeto de error al capturador del generador
  }

  return result; // Esto retornará el objeto del usuario en caso de éxito
};

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;

  try {
    const result = yield call(loginWithEmailPasswordAsync, email, password);

    // Procede con el flujo normal si no hay error
    const item = { user: result };
    setCurrentUser(item);
    yield put(loginUserSuccess(item));
    yield put(setAccounts(item.user.blog));
    yield put(setAccountsActive(item.user.blog[0]));

    history.push(
      history.location.state?.from?.pathname +
        history.location.state?.from?.search || adminRoot
    );
  } catch (error) {
    if (error && error.error) {
      yield put(loginUserError(error.error));
    } else if (error && error.message) {
      // Intenta extraer el mensaje de error del cuerpo de la respuesta
      yield put(loginUserError(error.message));
    } else {
      yield put(
        loginUserError('Hubo un error en el proceso de inicio de sesión.')
      );
    }
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async (
  name,
  email,
  password,
  bussines
) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/users/create_a_customer_and_blog_together`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: { name, email, password },
        blog: { name: bussines },
      }),
    }
  );

  if (response.ok) {
    const user = await response.json();
    return user;
  }

  const error = await response.json();
  throw new Error(error.message);
};

function* registerWithEmailPassword({ payload }) {
  const { name, email, password, bussines } = payload.user;
  const { history } = payload;
  try {
    const registerUser = yield call(
      registerWithEmailPasswordAsync,
      name,
      email,
      password,
      bussines
    );

    if (registerUser) {
      const item = { user: registerUser };
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      yield put(setAccounts(item.user.blog));
      yield put(setAccountsActive(item.user.blog?.[0]));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error.message));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history, token) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/v1/notification_devices/destroy?token_firebase=${token}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          token: localStorage.getItem('jwt'),
        },
      }
    );
    if (!response.ok) {
      if (response.headers.get('content-type').includes('application/json')) {
        const error = await response.json();
        throw new Error(error.message);
      } else {
        throw new Error('Server error');
      }
    }
  } catch (error) {
    console.error('Error during logout:', error);
    throw error;
  }
};

function* logout({ payload }) {
  const { history, token } = payload;

  try {
    yield call(logoutAsync, history, token);
    setCurrentUser();
    history.push('/user/login'); // Redirigir al usuario sólo después de eliminar el token exitosamente
  } catch (error) {
    console.error('Logout failed:', error);
  }
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/forgot_password/send_token_to_user`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user: { email } }),
    }
  );

  const result = await response.json();

  if (!response.ok) {
    throw result; // Esto arrojará el objeto de error al capturador del generador
  }
  return result; // Esto retornará el objeto del usuario en caso de éxito
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus) {
      yield put(forgotPasswordSuccess(forgotPasswordStatus));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error.error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/forgot_password/update_password_to_user`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: { token: resetPasswordCode, password: newPassword },
      }),
    }
  );

  const result = await response.json();

  if (!response.ok) {
    throw result;
  }
  return result;
};

// Saga para obtener el usuario actual
export function* getCurrentUserSaga() {
  const token = localStorage.getItem('jwt');
  if (token) {
    try {
      const user = yield call(getCurrentUser);
      yield put(loginUserSuccess(user));
      yield put(setAccounts(user.user.blog));
      yield put(setAccountsActive(user.user.blog[0]));
    } catch (error) {
      yield put(loginUserError(error.message));
    }
  }
}

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode, history } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    const item = { user: resetPasswordStatus };
    if (resetPasswordStatus) {
      yield put(resetPasswordSuccess(resetPasswordStatus.success));
      setCurrentUser(item);
      yield put(registerUserSuccess(item));
      yield put(setAccounts(item.user.blog));
      yield put(setAccountsActive(item.user.blog?.[0]));
      history.push(adminRoot);
    } else {
      yield put(resetPasswordError(resetPasswordStatus.error));
    }
  } catch (error) {
    yield put(resetPasswordError(error.error));
  }
}

export function* watchVerifyUserForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_VERIFY_PASSWORD, verifyForgotPasswordToken);
}

const verifyForgotPasswordTokenAsync = async (token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/forgot_password/verify_forgot_password_token?${token}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );

  const result = await response.json();

  if (!response.ok) {
    throw result;
  }
  return result;
};

function* verifyForgotPasswordToken({ payload }) {
  const { forgotVerifyPasswordCode } = payload;
  const { token, history } = forgotVerifyPasswordCode;
  const jwt = `token=${token}`;

  try {
    const verifyForgotPasswordStatus = yield call(
      verifyForgotPasswordTokenAsync,
      jwt
    );
    if (verifyForgotPasswordStatus) {
      yield put(forgotVerifyPasswordSuccess(verifyForgotPasswordStatus));
    } else {
      yield put(forgotVerifyPasswordError(verifyForgotPasswordStatus.error));
      history.push('/error');
    }
  } catch (error) {
    history.push('/error');
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(getCurrentUserSaga),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchVerifyUserForgotPassword),
  ]);
}
