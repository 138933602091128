/* eslint-disable require-yield */
/* eslint-disable object-shorthand */
/* eslint-disable func-names */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { getCurrentTime } from 'helpers/Utils';
import actionCable from 'actioncable';
import { eventChannel } from 'redux-saga';

// import contactsData from 'data/chat.contacts.json';
// import conversationsData from 'data/chat.conversations.json';
import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONVERSATIONS,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  START_CHAT_WEBSOCKET,
  CHAT_GET_CONVERSATIONS_CHATROOMS,
  CHAT_LOADER_MORE_CONVERSATIONS
} from '../contants';

import {
  getContactsSuccess,
  getContactsError,
  getConversationsSuccess,
  getConversationsError,
  getConversationsChatroomsSuccess,
  getConversationsChatroomsError,
  loaderMoreConversationsSuccess,
  loaderMoreConversationsError,
  newMessageSuccess
} from './actions';

function* loadContacts() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadContactsAsync);
    const { contacts } = response;
    const currentUser = yield select(
      (state) => state.authUser.currentUser.user.user.data.attributes
    );
    yield put(getContactsSuccess(contacts, currentUser));
  } catch (error) {
    yield put(getContactsError(error));
  }
}

const loadContactsAsync = async () => {
  const response2 = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/chatrooms/users`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('jwt'),
      },
    }
  );
  const contactsData = await response2.json();
  const contacts = contactsData.users;
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ contacts });
    }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* loadConversations(userId) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadConversationsAsync, userId);

    const { conversations, selectedUser } = response;
    const conversationsData = conversations.map((item) => {
      return {
        id: item.attributes.id,
        name: item.attributes.title,
        avatar: item.attributes.avatar_url,
        messages: item.attributes.messages,
        users: item.attributes.users,
      };
    });

    yield put(getConversationsSuccess(conversationsData, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const loadConversationsAsync = async () => {
  const response2 = await fetch(`${process.env.REACT_APP_API_URL}/v1/chatrooms`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem('jwt'),
    },
  });
  const chatrooms = await response2.json();

  const conversations = chatrooms.data;
  const selectedUser = parseInt(conversations[0].id, 10);

  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ conversations, selectedUser });
    }, 1000);
  })
    .then((response) => response)
    .catch((error) => error);
};
function* loadConversationsChatroom({ payload }) {
  try {
    const response = yield call(loadConversationsChatroomAsync, payload);
    const allConversations = yield select(
      (state) => state.chatApp.conversations
    );
    const conversation = allConversations.find((x) => x.id === payload);
    if (conversation) {
      const time = getCurrentTime();

      const sortedMessages = response.messages.messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      const updatedConversation = {
        ...conversation,
        messages: sortedMessages,
        lastPage: response.messages.has_more_messages,
        lastMessageTime: time,
      };
      const conversations = allConversations.map((x) =>
        x.id === payload ? updatedConversation : x
      );

      yield put(getConversationsChatroomsSuccess(conversations, payload));
    }
    } catch (error) {
      yield put(getConversationsChatroomsError(error));
    }
}

const loadConversationsChatroomAsync = async (chatroomId) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/chatrooms/chatroom_messages?id=${chatroomId}&page=1`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem('jwt'),
    },
  });
  
  const messages = await response.json();

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ messages });
    }, 1000);
  })
    // eslint-disable-next-line no-shadow
    .then((response) => response)
    .catch((error) => error);
};

function* loaderMoreConversationsChatroom({ payload }) {
  const {  userId, page } = payload;
  try {
    const response = yield call(loaderMoreConversationsChatroomAsync, userId, page);
    const allConversations = yield select(
      (state) => state.chatApp.conversations
    );
    const conversation = allConversations.find((x) => x.id === userId);
    if (conversation) {
      const time = getCurrentTime();

      // Concatena los mensajes antiguos con los nuevos y ordena por fecha.
      const combinedMessages = [...response.messages.messages, ...conversation.messages];
      const sortedMessages = combinedMessages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
      const updatedConversation = {
        ...conversation,
        messages: sortedMessages,
        lastPage: response.messages.has_more_messages,
        lastMessageTime: time,
      };

      const conversations = allConversations.map((x) =>
        x.id === userId ? updatedConversation : x
      );

      yield put(loaderMoreConversationsSuccess(conversations, userId));
    }
  } catch (error) {
    yield put(loaderMoreConversationsError(error));
  }
}


const loaderMoreConversationsChatroomAsync = async (userId, page) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/chatrooms/chatroom_messages?id=${userId}&last_message_id=${page}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      token: localStorage.getItem('jwt'),
    },
  });
  
  const messages = await response.json();

  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ messages });
    }, 1000);
  })
    // eslint-disable-next-line no-shadow
    .then((response) => response)
    .catch((error) => error);
};

// Objeto para almacenar los payload.id únicos
const processedPayloads = {};

// Saga principal para agregar mensajes a la conversación
function* addMessageToConversation({ payload }) {
  const { currentUserId, selectedUserId, message, allConversations } = payload;

  // Verificar si el payload.id ya ha sido procesado
  if (processedPayloads[payload.id]) {
    // Si es un payload duplicado, no realizar ninguna acción adicional
    return;
  }

  // Marcar el payload.id como procesado
  processedPayloads[payload.id] = true;

  try {
    const response = yield call(
      addMessageToConversationAsync,
      currentUserId,
      selectedUserId,
      message,
      allConversations
    );

    

    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const CableApp = {};
CableApp.cable = actionCable.createConsumer('wss://api.notecopies.app/cable');

function* handleChatWebSocket({ payload }) {
  const channel = yield call(createWebSocketChannel, payload);

  // Listen for messages received through the WebSocket channel
  yield takeEvery(channel, function* (data) {
    yield call(handleWebSocketMessage, data);
  });

  // Wait for the action to stop the WebSocket connection
  yield takeEvery('STOP_CHAT_WEBSOCKET', function () {
    CableApp.cable.subscriptions.remove(channel);
  });
}

// Create an event channel for the WebSocket messages
function createWebSocketChannel(ids) {
  return eventChannel((emit) => {
    const receivedHandler = (data) => {
      emit(data);
    };

    // Subscribe to the WebSocket channel and register the received handler
    const channel = CableApp.cable.subscriptions.create(
      {
        channel: 'ChatroomsChannel',
        room_ids: ids,
      },
      {
        received: receivedHandler,
      }
    );
    // Return a function to clean up and cancel the channel
    return () => {
      channel.unsubscribe();
    };
  });
}
function scrollToBottom() {
  const chatContainer = document.getElementById('chat-container');
  chatContainer.scrollTop = chatContainer.scrollHeight;
}
// Function to handle the WebSocket messages
function* handleWebSocketMessage(data) {
  console.log('WebSocket message received:', data);
  if (data) {
    const { user_id, chatroom_id, body, created_at } = data.message;

    try {
      const allConversations = yield select(
        (state) => state.chatApp.conversations
      );
      const conversation = allConversations.find((x) => x.id === chatroom_id);

      if (conversation) {
        const time = getCurrentTime();
        const newMessage = {
          user_id: user_id,
          time: time,
          body: body,
          created_at: created_at,
        };

        const updatedConversation = {
          ...conversation,
          messages: [...conversation.messages, newMessage],
          lastMessageTime: time,
        };
        const conversations = allConversations.map((x) =>
          x.id === chatroom_id ? updatedConversation : x
        );
        
        yield put(getConversationsSuccess(conversations, chatroom_id));
        yield put(newMessageSuccess(data));
      }
    } catch (error) {
      yield put(getConversationsError(error));
    }
  }
}

const addMessageToConversationAsync = async (
  currentUserId,
  selectedUserId,
  message,
  allConversations
  // eslint-disable-next-line consistent-return
) => {
  const conversation = allConversations.find((x) => x.id === selectedUserId);
  const time = getCurrentTime();

  if (conversation) {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/messages`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            token: localStorage.getItem('jwt'),
          },
          body: JSON.stringify({
            message: {
              body: message,
              chatroom_id: selectedUserId,
            },
            user_id: currentUserId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error('Error al enviar el mensaje');
      }

      const updatedConversation = {
        ...conversation,
        messages: [
          ...conversation.messages,
          {
            user_id: currentUserId,
            time,
            body: message,
          },
        ],
        lastMessageTime: time,
      };

      const conversations = allConversations.map((x) =>
        x.id === selectedUserId ? updatedConversation : x
      );
      

      return { conversations, selectedUser: selectedUserId };
    } catch (error) {
      throw error;
    }
  }
};

function* createNewConversation({ payload }) {
  try {
    const { currentUserId, selectedUserId, allConversations } = payload;
    const response = yield call(
      // eslint-disable-next-line no-use-before-define
      createNewConversationAsync,
      currentUserId,
      selectedUserId,
      allConversations
    );
    const { conversations, selectedUser } = response;
    yield put(getConversationsSuccess(conversations, selectedUser));
  } catch (error) {
    yield put(getConversationsError(error));
  }
}

const createNewConversationAsync = async (
  currentUserId,
  selectedUserId,
  allConversations
) => {
  const conversation = {
    id: allConversations.length + 1,
    users: [currentUserId, selectedUserId],
    lastMessageTime: '-',
    messages: [],
  };

  allConversations.splice(0, 0, conversation);
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({
        conversations: allConversations,
        selectedUser: selectedUserId,
      });
    }, 500);
  })
    .then((response) => response)
    .catch((error) => error);
};

export function* watchGetContact() {
  yield takeEvery(CHAT_GET_CONTACTS, loadContacts);
}

export function* watchGetConversation() {
  yield takeEvery(CHAT_GET_CONVERSATIONS, loadConversations);
}

export function* watchGetConversationChatroom() {
  yield takeEvery(CHAT_GET_CONVERSATIONS_CHATROOMS, loadConversationsChatroom);
}

export function* watchloaderMoreConversationChatroom() {
  yield takeEvery(CHAT_LOADER_MORE_CONVERSATIONS, loaderMoreConversationsChatroom);
}

export function* watchAddMessageToConversation() {
  yield takeEvery(CHAT_ADD_MESSAGE_TO_CONVERSATION, addMessageToConversation);
}

export function* watchCreateConversation() {
  yield takeEvery(CHAT_CREATE_CONVERSATION, createNewConversation);
}

export function* chatWebSocketSaga() {
  yield takeEvery(START_CHAT_WEBSOCKET, handleChatWebSocket);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetContact),
    fork(watchGetConversation),
    fork(watchAddMessageToConversation),
    fork(watchGetConversationChatroom),
    fork(watchloaderMoreConversationChatroom),
    fork(watchCreateConversation),
    fork(chatWebSocketSaga),
  ]);
}
