/* eslint-disable import/prefer-default-export */
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import {
  perfilUserSuccess,
  perfilUserFailure,
  getInvoiceSuccess,
  getInvoiceFailure,
  getContactPaymentFailure,
  getContactPaymentSuccess,
  // unSubcribedFailure,
  // unSubcribedSuccess,
} from './actions';
import {
  GET_INVOICE,
  GET_PERFIL_USER,
  GET_CONTANT_PAYMENT,
  UN_SUBSCRIBED_PLAN_USER,
} from '../contants';

function* PerfilUserGestor() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(PerfilUserGestorAsync);
    const { contact } = response.plans;

    yield put(perfilUserSuccess(contact));
  } catch (error) {
    yield put(perfilUserFailure(error));
  }
}

const PerfilUserGestorAsync = async () => {
  const response2 = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/users/gestor_info_contact`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('jwt'),
      },
    }
  );
  const plans = await response2.json();
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ plans });
    }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* PerfilUserInvoiceGestor() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(PerfilUserInvoiceGestorAsync);
    const { plans } = response;

    yield put(getInvoiceSuccess(plans));
  } catch (error) {
    yield put(getInvoiceFailure(error));
  }
}

const PerfilUserInvoiceGestorAsync = async () => {
  const response2 = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/users/gestor_invoice_contact`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('jwt'),
      },
    }
  );
  const plans = await response2.json();

  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ plans });
    }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* PerfilUserPaymentGestor() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(PerfilUserPaymentGestorAsync);

    const { data } = response.plans;
    yield put(getContactPaymentSuccess(data));
  } catch (error) {
    yield put(getContactPaymentFailure(error));
  }
}

const PerfilUserPaymentGestorAsync = async () => {
  const response2 = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/users/gestor_info_contact_payment`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('jwt'),
      },
    }
  );
  const plans = await response2.json();

  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ plans });
    }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

// eslint-disable-next-line require-yield
function* unSubcribedPlanUser({ payload }) {
  console.log(payload);
  // yield put(unSubcribedSuccess(payload));
  // try {
  //   // eslint-disable-next-line no-use-before-define
  //   // const response = yield call(unSubcribedPlanUserAsync);

  //   // const { data } = response.plans;
  //   yield put(unSubcribedSuccess(payload));
  // } catch (error) {
  //   yield put(unSubcribedFailure(payload));
  // }
}

// const unSubcribedPlanUserAsync = async () => {
//   const response2 = await fetch('${process.env.REACT_APP_API_URL}/v1/users/gestor_info_contact_payment2', {
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       token: localStorage.getItem('jwt'),
//     },
//   });
//   const plans = await response2.json();

//   // eslint-disable-next-line no-return-await
//   return await new Promise((success) => {
//     setTimeout(() => {
//       success({ plans });
//     }, 2000);
//   })
//     .then((response) => response)
//     .catch((error) => error);
// };

export function* watchGetPerfilInvoiceUser() {
  yield takeEvery(GET_INVOICE, PerfilUserInvoiceGestor);
}
export function* watchGetPerfilUser() {
  yield takeEvery(GET_PERFIL_USER, PerfilUserGestor);
}
export function* watchGetPaymentUser() {
  yield takeEvery(GET_CONTANT_PAYMENT, PerfilUserPaymentGestor);
}
export function* watchUnsubribedPlanUser() {
  yield takeEvery(UN_SUBSCRIBED_PLAN_USER, unSubcribedPlanUser);
}

export default function* rootSaga() {
  yield all([
    // fork(watchGetPerfilUser),
    fork(watchGetPerfilInvoiceUser),
    fork(watchGetPaymentUser),
    fork(watchUnsubribedPlanUser),
  ]);
}
