import {
  ADD_TO_CART,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS,
  REMOVE_FROM_CART,
  CLEAR_CART,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  SUBSCRIBED_SERVICE,
  SUBSCRIBED_SERVICE_SUCCESS,
  SUBSCRIBED_SERVICE_FAILURE,
  GET_SERVICE_ADDONS_SUCCESS,
  GET_SERVICE_ADDONS_FAILURE,
  GET_SERVICE_ADDONS,
  GET_ALL_SUBCRIPTION_USER,
  GET_ALL_SUBCRIPTION_USER_SUCCESS,
  GET_ALL_SUBCRIPTION_USER_FAILURE,
} from 'redux/contants';

// actions.js
export const getProducts = () => ({
  type: GET_PRODUCTS,
});

export const getProductsSuccess = (products) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
});

export const getProductsError = (error) => ({
  type: GET_PRODUCTS_ERROR,
  payload: error,
});

export const getServiceAddons = (productId) => ({
  type: GET_SERVICE_ADDONS,
  payload: productId,
});

export const getServiceAddonsSuccess = (addons) => ({
  type: GET_SERVICE_ADDONS_SUCCESS,
  payload: addons,
});

export const getServiceAddonsError = (error) => ({
  type: GET_SERVICE_ADDONS_FAILURE,
  payload: error,
});
export const subcribedService = () => ({
  type: SUBSCRIBED_SERVICE,
});

export const subcribedServiceSuccess = (products) => ({
  type: SUBSCRIBED_SERVICE_SUCCESS,
  payload: products,
});

export const subcribedServiceError = (error) => ({
  type: SUBSCRIBED_SERVICE_FAILURE,
  payload: error,
});

export const addToCart = (product) => ({
  type: ADD_TO_CART,
  payload: product,
});

export const removeFromCart = (productId) => ({
  type: REMOVE_FROM_CART,
  payload: productId,
});

export const clearCart = () => ({
  type: CLEAR_CART,
});

export const checkoutRequest = () => ({
  type: CHECKOUT_REQUEST,
});

export const checkoutSuccess = () => ({
  type: CHECKOUT_SUCCESS,
});

export const checkoutFailure = (error) => ({
  type: CHECKOUT_FAILURE,
  payload: error,
});

export const getAllSubscriptionUser = () => ({
  type: GET_ALL_SUBCRIPTION_USER,
});

export const getAllSubscriptionUserSuccess = (AllSubscription) => ({
  type: GET_ALL_SUBCRIPTION_USER_SUCCESS,
  payload: AllSubscription,
});

export const getAllSubscriptionUserError = (error) => ({
  type: GET_ALL_SUBCRIPTION_USER_FAILURE,
  payload: error,
});
