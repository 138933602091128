import { ACCOUNTS, ACCOUNT_ACTIVE } from '../contants';

export const setAccounts = (payload) => {
  return {
    type: ACCOUNTS,
    payload,
  };
};

export const setAccountsActive = (payload) => {
  return {
    type: ACCOUNT_ACTIVE,
    payload,
  };
};
