/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';


const initialState = {
  notifications_devises: [],
  currenTokenDeviced: '',
};

export const getNotifications = createAsyncThunk(
  'notification/getNotifications',
  async (rejectWithValue) => {
    try {
      const response = await axios.get(  // Cambiado de delete a get
        `${process.env.REACT_APP_API_URL}/v1/notification_devices`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const devices = await response.data;
      return devices;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const createDeviceNotification = createAsyncThunk(
  'notification/createDeviceNotification',
  async (notification,  {rejectWithValue}) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/notification_devices`,
        { notification },  // esto se convierte en data
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const devices = await response.data;
      console.log('devices', devices)
      return devices;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const deleteDeviceNotification = createAsyncThunk(
  'notification/deleteDeviceNotification',
  async (token,  {rejectWithValue}) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/v1/notification_devices/${token}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const devices = await response.data;
      return devices;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);




const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addCurrentTokenDeviced: (state, action) => {
      state.currenTokenDeviced = action.payload
    }
  },
  extraReducers: {
    [getNotifications.fulfilled]: (state, action) => {
      state.notifications_devises = action.payload;
    },
    [createDeviceNotification.fulfilled]: (state, action) => {
      state.notifications_devises.push(action.payload);
    },
    // [deleteDeviceNotification.fulfilled]: (state, action) => {
    //   state.notifications_devises.push(action.payload);
    // },
  },
});

export const { addCurrentTokenDeviced } = notificationSlice.actions

export default notificationSlice.reducer;
