/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
  products: [],
  plans: [],
  subscripcions: [],
  requestPaymentInfo: {},
  requestPaymentInfoLoading: false,
  productsLoading: false,
  getPlansByIdLoading: false,
  getSubcriptionsLoading: false,
  subscribingLoading: false,
  ButtonsubscribingLoading: '',
};

export const products = createAsyncThunk(
  'services/productsPlans',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/gestor_products_subscriptions`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const subscripcion = await response.data;
      return subscripcion.products;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const getSubcriptions = createAsyncThunk(
  'services/Subcriptions',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/gestor_contact_plans_subscriptions`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const subscripcion = await response.data;
      console.log('subscripcion', subscripcion);
      return subscripcion.subscriptions;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const getPlansById = createAsyncThunk(
  'services/products',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/gestor_plans_by_product_id_subscriptions?product_id=${id}`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const plans = await response.data;
      console.log(plans);
      return plans.plans;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const subscribe = createAsyncThunk(
  'services/subscribe',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/v1/users/gestor_info_SubscriptionPlan`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
          body: data,
        }
      );
      const plans = await response.data;

      return plans.subscription;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const requestPayment = createAsyncThunk(
  'services/requestPayment',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/users/gestor_info_contact_payment`,
        {
          headers: {
            'content-Type': 'application/json',
            token: localStorage.getItem('jwt'),
          },
        }
      );
      const requestPaymentData = await response.data;

      return requestPaymentData.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.data.message);
    }
  }
);

export const perfilUserSlice = createSlice({
  name: 'subcriptions',
  initialState,
  reducers: {
    subscribingButton: (state, action) => {
      state.ButtonsubscribingLoading = action.payload;
    },
  },
  extraReducers: {
    [products.pending]: (state) => {
      state.productsLoading = true;
    },
    [products.fulfilled]: (state, action) => {
      state.products = action.payload;
      state.productsLoading = false;
    },
    [products.rejected]: (state) => {
      state.productsLoading = false;
    },
    [getPlansById.pending]: (state) => {
      state.getPlansByIdLoading = true;
    },
    [getPlansById.fulfilled]: (state, action) => {
      state.getPlansById = action.payload;
      state.getPlansByIdLoading = false;
    },
    [getPlansById.rejected]: (state) => {
      state.getPlansByIdLoading = false;
    },
    [getSubcriptions.pending]: (state) => {
      state.getSubcriptionsLoading = true;
    },
    [getSubcriptions.fulfilled]: (state, action) => {
      state.subscripcions = action.payload;
      state.getSubcriptionsLoading = false;
    },
    [getSubcriptions.rejected]: (state) => {
      state.getPlansByIdLoading = false;
    },
    [subscribe.pending]: (state) => {
      state.subscribingLoading = true;
    },
    [subscribe.rejected]: (state) => {
      state.subscribingLoading = false;
      state.ButtonsubscribingLoading = '';
    },
    [subscribe.fulfilled]: (state, action) => {
      state.subscripcions.push(action.payload.plan);
      state.subscribingLoading = false;
      state.ButtonsubscribingLoading = '';
    },
    [requestPayment.pending]: (state) => {
      state.requestPaymentInfoLoading = true;
    },
    [requestPayment.rejected]: (state) => {
      state.requestPaymentInfoLoading = false;
    },
    [requestPayment.fulfilled]: (state, action) => {
      state.requestPaymentInfo = action.payload;
      state.requestPaymentInfoLoading = false;
    },
  },
});

export const { subscribingButton } = perfilUserSlice.actions;

export default perfilUserSlice.reducer;
