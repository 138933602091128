/* eslint-disable import/prefer-default-export */
import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import {
  getProductsSuccess,
  getProductsError,
  getServiceAddonsSuccess,
  getServiceAddonsError,
  getAllSubscriptionUserSuccess,
  getAllSubscriptionUserError,
} from './actions';
import {
  GET_PRODUCTS,
  GET_SERVICE_ADDONS,
  GET_ALL_SUBCRIPTION_USER,
} from '../contants';

function* loadServices() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadServicesAsync);
    const { plans } = response;

    yield put(getProductsSuccess(plans.plans));
  } catch (error) {
    yield put(getProductsError(error));
  }
}

const loadServicesAsync = async () => {
  const response2 = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/users/gestor_plans_subscriptions`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('jwt'),
      },
    }
  );
  const plans = await response2.json();
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ plans });
    }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* loadContactSubscrionsPlans() {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadContactSubscrionsPlansAsync);
    yield put(getAllSubscriptionUserSuccess(response.plans));
  } catch (error) {
    yield put(getAllSubscriptionUserError(error));
  }
}

const loadContactSubscrionsPlansAsync = async () => {
  const response2 = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/users/gestor_contact_plans_subscriptions`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('jwt'),
      },
    }
  );
  const plans = await response2.json();
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ plans });
    }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

function* loadServicesAddon({ payload }) {
  try {
    // eslint-disable-next-line no-use-before-define
    const response = yield call(loadServicesAddonAsync, payload);
    const { plans } = response;

    yield put(getServiceAddonsSuccess(plans.addons));
  } catch (error) {
    yield put(getServiceAddonsError(error));
  }
}

const loadServicesAddonAsync = async (payload) => {
  const response2 = await fetch(
    `${process.env.REACT_APP_API_URL}/v1/users/gestor_plans_subscriptions_addons?product_id=${payload}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        token: localStorage.getItem('jwt'),
      },
    }
  );
  const plans = await response2.json();
  // eslint-disable-next-line no-return-await
  return await new Promise((success) => {
    setTimeout(() => {
      success({ plans });
    }, 2000);
  })
    .then((response) => response)
    .catch((error) => error);
};

export function* watchGetServices() {
  yield takeEvery(GET_PRODUCTS, loadServices);
}

export function* watchGetServicesAddons() {
  yield takeEvery(GET_SERVICE_ADDONS, loadServicesAddon);
}
export function* watchGetContatSubscriptions() {
  yield takeEvery(GET_ALL_SUBCRIPTION_USER, loadContactSubscrionsPlans);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetServices),
    fork(watchGetServicesAddons),
    fork(watchGetContatSubscriptions),
  ]);
}
