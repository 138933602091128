import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  CHECKOUT_REQUEST,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAILURE,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_PRODUCTS,
  SUBSCRIBED_SERVICE,
  SUBSCRIBED_SERVICE_SUCCESS,
  SUBSCRIBED_SERVICE_FAILURE,
  GET_SERVICE_ADDONS_SUCCESS,
  GET_SERVICE_ADDONS_FAILURE,
  GET_SERVICE_ADDONS,
  GET_ALL_SUBCRIPTION_USER,
  GET_ALL_SUBCRIPTION_USER_SUCCESS,
  GET_ALL_SUBCRIPTION_USER_FAILURE,
} from 'redux/contants';

const initialState = {
  products: [],
  serviceAddons: [],
  subscriptions: [],
  cart: [],
  loading: false,
  loadingProducts: false,
  error: null,
  checkoutSuccess: false,
  loadingsubscriptions: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        cart: [...state.cart, action.payload],
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        cart: state.cart.filter((product) => product.id !== action.payload),
      };
    case CLEAR_CART:
      return {
        ...state,
        cart: [],
      };
    case CHECKOUT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        checkoutSuccess: false,
      };
    case CHECKOUT_SUCCESS:
      return {
        ...state,
        loading: false,
        checkoutSuccess: true,
      };
    case CHECKOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        checkoutSuccess: false,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loadingProducts: true,
      };
    case GET_PRODUCTS_ERROR:
      return {
        ...state,
        products: action.payload,
        loadingProducts: true,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: action.payload,
        loadingProducts: false,
      };
    case SUBSCRIBED_SERVICE:
      return {
        ...state,
        subscriptions: action.payload,
        loadingsubscriptions: true,
      };
    case SUBSCRIBED_SERVICE_FAILURE:
      return {
        ...state,
        subscriptions: action.payload,
        loadingsubscriptions: true,
      };
    case SUBSCRIBED_SERVICE_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
        loadingsubscriptions: false,
      };

    case GET_SERVICE_ADDONS:
      return {
        ...state,
      };
    case GET_SERVICE_ADDONS_FAILURE:
      return {
        ...state,
      };
    case GET_SERVICE_ADDONS_SUCCESS:
      return {
        ...state,
        serviceAddons: action.payload,
      };

    case GET_ALL_SUBCRIPTION_USER:
      return {
        ...state,
      };
    case GET_ALL_SUBCRIPTION_USER_FAILURE:
      return {
        ...state,
      };
    case GET_ALL_SUBCRIPTION_USER_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
