// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';
import {
  CHAT_GET_CONTACTS,
  CHAT_GET_CONTACTS_SUCCESS,
  CHAT_GET_CONTACTS_ERROR,
  CHAT_GET_CONVERSATIONS,
  CHAT_GET_CONVERSATIONS_SUCCESS,
  CHAT_GET_CONVERSATIONS_ERROR,
  CHAT_LOADER_MORE_CONVERSATIONS,
  CHAT_LOADER_MORE_CONVERSATIONS_SUCCESS,
  CHAT_LOADER_MORE_CONVERSATIONS_ERROR,
  CHAT_GET_CONVERSATIONS_CHATROOMS,
  CHAT_GET_CONVERSATIONS_CHATROOMS_SUCCESS,
  CHAT_GET_CONVERSATIONS_CHATROOMS_ERROR,
  CHAT_ADD_MESSAGE_TO_CONVERSATION,
  CHAT_CREATE_CONVERSATION,
  CHAT_SEARCH_CONTACT,
  CHAT_CHANGE_CONVERSATION,
  START_CHAT_WEBSOCKET,
  CHAT_WEBSOCKET_MESSAGE_RECEIVED,
  NEW_MESSAGES_SUCCESS,
} from '../contants';

export const getContacts = () => ({
  type: CHAT_GET_CONTACTS,
});

export const getContactsSuccess = (contacts, currentUser) => {
  return {
    type: CHAT_GET_CONTACTS_SUCCESS,
    payload: { contacts, currentUser },
  };
};
export const chatWebsocketMessageReceived = (data) => ({
  type: CHAT_WEBSOCKET_MESSAGE_RECEIVED,
  payload: { data },
});
export const newMessageSuccess = (data) => ({
  type: NEW_MESSAGES_SUCCESS,
  payload: { data },
});

export const getContactsError = (error) => ({
  type: CHAT_GET_CONTACTS_ERROR,
  payload: error,
});
export const startChatWebSocket = (chatroomIds) => ({
  type: START_CHAT_WEBSOCKET,
  payload: chatroomIds,
});
export const loaderMoreConversations = (userId, page) => ({
  type: CHAT_LOADER_MORE_CONVERSATIONS,
  payload: { userId, page },
});
export const loaderMoreConversationsSuccess = (conversations, selectedUser) => ({
  type: CHAT_LOADER_MORE_CONVERSATIONS_SUCCESS,
  payload: { conversations, selectedUser },
});

export const loaderMoreConversationsError = (error) => ({
  type: CHAT_LOADER_MORE_CONVERSATIONS_ERROR,
  payload: error,
});
export const getConversations = (userId) => ({
  type: CHAT_GET_CONVERSATIONS,
  payload: userId,
});
export const getConversationsSuccess = (conversations, selectedUser) => ({
  type: CHAT_GET_CONVERSATIONS_SUCCESS,
  payload: { conversations, selectedUser },
});

export const getConversationsError = (error) => ({
  type: CHAT_GET_CONVERSATIONS_ERROR,
  payload: error,
});

export const getConversationsChatrooms = (chatroomId) => ({
  type: CHAT_GET_CONVERSATIONS_CHATROOMS,
  payload: chatroomId,
});
export const getConversationsChatroomsSuccess = (conversations, selectedUser) => ({
  type: CHAT_GET_CONVERSATIONS_CHATROOMS_SUCCESS,
  payload: { conversations, selectedUser },
});

export const getConversationsChatroomsError = (error) => ({
  type: CHAT_GET_CONVERSATIONS_CHATROOMS_ERROR,
  payload: error,
});

export const addMessageToConversation = (
  currentUserId,
  selectedUserId,
  message,
  allConversations
) => ({
  type: CHAT_ADD_MESSAGE_TO_CONVERSATION,
  payload: {
    id: uuidv4(),
    currentUserId,
    selectedUserId,
    message,
    allConversations,
  },
});

export const createConversation = (
  currentUserId,
  selectedUserId,
  allConversations
) => {
  return {
    type: CHAT_CREATE_CONVERSATION,
    payload: { currentUserId, selectedUserId, allConversations },
  };
};

export const searchContact = (keyword) => ({
  type: CHAT_SEARCH_CONTACT,
  payload: keyword,
});

export const changeConversation = (userId) => ({
  type: CHAT_CHANGE_CONVERSATION,
  payload: userId,
});
