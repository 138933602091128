import { GET_PERFIL } from '../contants';

const initialState = {
  perfil: {},
};

export default function perfilReducer(state = initialState, action) {
  switch (action.type) {
    case GET_PERFIL:
      return {
        ...state,
        perfil: action.payload,
      };
    default:
      return { ...state };
  }
}
